export default function testimonials() {
    console.log('testimonials');
    const testimonials_swiper = new Swiper(".section-testimonials__swiper", {
        slidesPerView: 1,
        spaceBetween: 30,
        breakpoints: {
            767: {
                slidesPerView: 2
            },
            991: {
                slidesPerView: 3,
                spaceBetween: 30
            }
        },
        pagination: {
            el: ".section-testimonials__swiper-pagination",
            clickable: true,
        },
        navigation: {
            nextEl: ".section-testimonials__swiper-button-next",
            prevEl: ".section-testimonials__swiper-button-prev",
        },
        autoplay: true,
        loop: true
    });
}