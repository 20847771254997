// import Swiper from "swiper";

export default function front_page_swiper() {
    let swiper = new Swiper(".section-hero__swiper-bottom", {
        loop: true,
        slidesPerView: 4,
        allowSlideNext: false,
        allowSlidePrev: false
    });
    let swiper2 = new Swiper(".section-hero__swiper-top", {
        loop: true,
        autoplay: {
            delay: 3000,
        },
        thumbs: {
            swiper: swiper,
        },
    });
}