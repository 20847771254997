import example from './modules/example';
import navigation from './modules/navigation';
import front_page_swiper from './modules/front-page-swiper';
import testimonials from './modules/testimonials';
// ...

import '../scss/styles.scss';

document.addEventListener('DOMContentLoaded', () => {
  example();
  navigation();
  front_page_swiper();
  AOS.init({
    // once: true,
  });
  testimonials();
});