export default function navigation() {
    // let subMenus = document.querySelectorAll(".sub-menu");
    // subMenus.forEach(function (subMenu) {
    //     let container = document.createElement("div");
    //     container.className = "sub-menu-container";
    //     subMenu.parentNode.insertBefore(container, subMenu);
    //     container.appendChild(subMenu);
    // });

    const menuItems = document.querySelectorAll(".menu-item");
    const parentMenuItems = document.querySelectorAll('.menu-item-has-children');
    const header = document.querySelector('.header');

    menuItems.forEach(function (item) {
        item.addEventListener("mouseover", function () {
            menuItems.forEach(item => {
                item.classList.add("gray");
            });
            this.classList.remove("gray");
        });

        item.addEventListener("mouseout", function () {
            menuItems.forEach(item => {
                item.classList.remove("gray");
            });
        });
    });


    window.addEventListener('scroll', () => {
        const scrollPosition = window.scrollY;
        const header = document.querySelector('header');
        const shouldHide = scrollPosition > header.offsetHeight;
        header.classList.toggle('hide', shouldHide);
        header.classList.toggle('scrolled', scrollPosition >= 1);
    });

    if (parentMenuItems.length > 0) {
        parentMenuItems.forEach(el => {
            el.addEventListener('mouseover', function () {
                header.classList.add('hover');
            });

            el.addEventListener('mouseout', function () {
                header.classList.remove('hover');
            });
        })
    }

    const iconMenu = document.querySelector('.menu__icon');
    const menuBody = document.querySelector('.menu');

    if (iconMenu) {
        iconMenu.addEventListener('click', function (e) {
            document.body.classList.toggle('_lock');
            iconMenu.classList.toggle('_active');
            menuBody.classList.toggle('_active');
        })
    }

    if (parentMenuItems.length > 0) {
        parentMenuItems.forEach(el => {
            el.addEventListener('click', function () {
                el.classList.toggle('_active');
                let sub_menu = el.querySelector('.sub-menu');
                sub_menu.classList.toggle('_active');
            });
        })
    }
}